/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import api from '../../../../services/app/client-server/axios-config.service'
import { useParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import MetersData from '../../../components/Meters/MetersData'
import NameData from '../../../components/Meters/NameData'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../../store/slices/app/comps/snackbar'

const EditMeters = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { meters_id } = useParams()
  const idMeasurement = parseInt(localStorage.getItem('measurement_id'), 10)

  const [isLoading, setIsLoading] = useState(true)
  const [isButton, setIsButton] = useState(true)
  const [validationErrors, setValidationErrors] = useState({})

  const [manufacturerName, setManufacturerName] = useState('')
  const [number, setNumber] = useState('')
  const [numberRegistry, setNumberRegistry] = useState('')
  const [nameSi, setNameSi] = useState('')
  const [mpi_text, setMpiText] = useState('')
  const [mpi_value, setMpiValue] = useState('')
  const [metersData, setMetersData] = useState(null)

  const token = useSelector(state => state.authReducer.authData.token)

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/meters/${idMeasurement}/${meters_id}/edit`
        const headers = {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        }
        const response = await api.get(apiUrl, { headers })
        setMetersData(response.data.data)
        setManufacturerName(response.data.data.manufacturer.name)
        setNumber(response.data.data.number)
        setNumberRegistry(response.data.data.number_registry)
        setNameSi(response.data.data.name_si)
        setMpiText(response.data.data.mpi_text)
        setMpiValue(response.data.data.mpi_value)
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [idMeasurement, meters_id])

  const handleSaveMeters = async () => {
    if (!isButton) {
      return
    }

    setIsButton(false)
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/api/meters/${idMeasurement}/${meters_id}`
      const headers = {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      }

      const data = {
        manufacturer: {
          name: manufacturerName,
        },
        number: number,
        number_registry: numberRegistry,
        name_si: nameSi,
        mpi_text: mpi_text,
        mpi_value: mpi_value,
      }

      const response = await api.put(apiUrl, data, { headers })

      if (response.data && response.data.message) {
        dispatch(setMessage(response.data.message))
      }
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      navigate('/metriva/meters')
    } catch (error) {
      setIsButton(true)
      if (error.response && error.response.data && error.response.data.errors) {
        const serverErrors = error.response.data.errors
        setValidationErrors(serverErrors)
        dispatch(setType('error'))
        dispatch(setOpenAlert(true))
        dispatch(setMessage('Ошибка валидации. Убедитесь, что вы заполнили все поля!'))
        console.error(error)
      }
    }
  }

  const navigateToProtocols = () => {
    navigate('/metriva/meters')
  }

  return (
    <main className='main'>
      <div className='cases__form_add'>
        <div className='path_to_back' onClick={navigateToProtocols}>
          <span className='fas fa-arrow-left icon_back'></span>
          <p>Назад к базе приборов</p>
        </div>

        {isLoading ? null : (
          <React.Fragment>
            <div className='path_to_edit'>
              <h4>Прибор № {metersData?.id}</h4>
              <span className='fas fa-times icon_back' onClick={navigateToProtocols}></span>
            </div>

            <div className='box'>
              <h2>Данные прибора</h2>

              <MetersData
                numberRegistry={numberRegistry}
                setNumberRegistry={setNumberRegistry}
                number={number}
                setNumber={setNumber}
                manufacturerName={manufacturerName}
                setManufacturerName={setManufacturerName}
                validationErrors={validationErrors}
              />

              <NameData validationErrors={validationErrors} nameSi={nameSi} setNameSi={setNameSi} />

              <h2>Интервал поверки</h2>

              <div className='mechanical_characteristics_box_min_max'>
                <div className='mechanical_characteristics_max'>
                  <p>МПИ</p>
                  <input
                    className='input'
                    placeholder='Межповерочный интервал'
                    value={mpi_text}
                    onChange={e => setMpiText(e.target.value)}
                  />
                </div>

                <div className='mechanical_characteristics_max'>
                  <p>Межповерочный интервал</p>
                  <input
                    className='input'
                    placeholder='Межповерочный интервал'
                    value={mpi_value}
                    onChange={e => setMpiValue(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <button
              className={`save_button ${!isButton ? 'disabled' : ''}`}
              onClick={handleSaveMeters}
              disabled={!isButton}
            >
              Сохранить
            </button>
          </React.Fragment>
        )}
      </div>
    </main>
  )
}

export default EditMeters
