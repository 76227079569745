/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker'
import 'dayjs/locale/ru'
import CheckCircleIcon from '../../../../img/icon/CheckCircleIcon'
import VisibilityToggleIcon from '../../../../img/icon/VisibilityToggleIcon'

dayjs.locale('ru')

const StyledDatePicker = styled(MuiDatePicker)(() => ({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    backgroundColor: '#ffffff',
    paddingRight: '20px',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#d7ddea',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#d7ddea',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
      borderColor: '#0084e2',
    },
  },
  '& .MuiInputBase-root': {
    borderRadius: '12px',
  },
  '& .MuiPickersDay-day': {
    borderRadius: '12px',
  },
  '& .MuiPickersCalendar-transitionContainer': {
    borderRadius: '12px',
  },
  '& .MuiSvgIcon-root': {
    color: '#0084e2',
  },
}))

const DatePicker = props => {
  const {
    selectedDate,
    dateChange,
    minDate,
    maxDate,
    label,
    isDisabled = false,
    disableFuture = false,
    isRequired = false,
  } = props

  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    if (!isDisabled) {
      setOpen(true)
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const verificationDateLabelIcon = selectedDate ? (
    <CheckCircleIcon sx={{ color: '#63E6BE', marginLeft: '8px' }} />
  ) : (
    <VisibilityToggleIcon sx={{ color: '#FFD43B', marginLeft: '8px' }} />
  )

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
      <StyledDatePicker
        value={selectedDate ? dayjs(selectedDate) : null}
        onChange={dateChange}
        minDate={minDate}
        maxDate={maxDate}
        disableFuture={disableFuture}
        label={
          label ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '20px',
                marginLeft: '4px',
                background: 'white',
                padding: '0 8px',
                borderRadius: '8px',
              }}
            >
              {label}
              {isRequired && (
                <span style={{ marginBottom: '-3px' }}>{verificationDateLabelIcon}</span>
              )}
            </div>
          ) : null
        }
        disabled={isDisabled}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        slotProps={{
          textField: {
            inputProps: {
              readOnly: false,
            },
            InputLabelProps: {
              shrink: true,
            },
            onClick: handleOpen,
          },
        }}
      />
    </LocalizationProvider>
  )
}

export default DatePicker
