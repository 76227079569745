// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Collapse, Typography, useMediaQuery } from '@mui/material'
import moment from 'moment'

import InfoCircle from '../../../img/icon/InfoCircle'
import PdfIcon from '../../../img/icon/PdfIcon'
import ArchiveIcon from '../../../img/icon/ArchiveIcon'
import RestoreIcon from '../../../img/icon/RestoreIcon'
import ExportIcon from '../../../img/icon/Export.jsx'
import PencilIcon from '../../../img/icon/PencilIcon'
import DeleteIcon from '../../../img/icon/DeleteIcon'
import CopyIcon from '../../../img/icon/Copy'
import CheckCircleIcon from '../../../img/icon/CheckCircleIcon'
import ExitIcon from '../../../img/icon/ExitIcon'

import Tooltip from '../../../ui/tooltip/index'
import Button from '../../../ui/button/index.tsx'
import RadioButton from '../../../ui/radio-button/index'
import theme from '../../../utils/theme.ts'

import Csv from '../../../services/app/files/protocol-csv-excel-download.service.js'
import RightSideContainer from '../../components/LocalElements/RightSideContainer/RightSideContainer'
import FilterMeasurement from '../../components/LocalElements/Filter/FilterMeasurement'
import FilterUsers from '../../components/LocalElements/Filter/FilterUsers'
import DatePicker from '../../components/LocalElements/DatePicker/DatePicker'
import FilterCompany from '../../components/LocalElements/Filter/FilterCompany'
import BasicTabs from '../../components/LocalElements/TabsMenu/TabsMenu'
import Table from '../../components/LocalElements/Table/Table'
import R from '../../../services/app/client-server/request.service.js'

import {
  setOpen,
  setTitle,
  setChildren,
  setConfirmAction,
  setBA,
  setBD,
  showBA,
  resetState,
} from '../../../store/slices/app/comps/modal'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../store/slices/app/comps/snackbar'
import {
  setMeasurementIdFilter,
  setUserId,
  setStatusId,
  setApprovedTransfer,
  setApprovedArshin,
  setApprovedFsa,
  setDateFrom,
  setDateTo,
  setSelectedProtocols,
  setViewVariantMain,
} from '../../../store/slices/app/views/protocols'

import {
  refreshReceiveds,
  refreshConditions,
  refreshIssueCase,
  refreshPersonalMetrolog,
  refreshTickets,
} from '../../../store/slices/app/controlers/updater'

import { refreshProtocols } from '../../../store/slices/app/controlers/updater'
import { setOpen as setOpenRightContainer } from '../../../store/slices/app/comps/rightContainer'

const AllProtocols = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isMobile = useMediaQuery(theme.breakpoints.up('lg'))
  const userRole = useSelector(state => state.headerReducer.role)
  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser

  const protocolsData = useSelector(state => state.protocolReducer.protocolsList)
  const isTrusted = useSelector(state => state.headerReducer.isTrusted)
  const viewVariant = useSelector(state => state.protocolReducer.viewVariantMain)
  const selectedProtocols = useSelector(state => state.protocolReducer.selectedProtocols)
  const measurementIdFilter = useSelector(state => state.protocolReducer.measurementIdFilter)
  const userId = useSelector(state => state.protocolReducer.userId)
  const statusProtocol = useSelector(state => state.protocolReducer.statusId)
  const approvedTransfer = useSelector(state => state.protocolReducer.approvedTransfer)
  const approvedArshin = useSelector(state => state.protocolReducer.approvedArshin)
  const approvedFsa = useSelector(state => state.protocolReducer.approvedFsa)
  const dateFrom = useSelector(state => state.protocolReducer.dateFrom)
  const dateTo = useSelector(state => state.protocolReducer.dateTo)

  const resetActions = [
    setMeasurementIdFilter,
    setUserId,
    setStatusId,
    setApprovedTransfer,
    setApprovedArshin,
    setApprovedFsa,
    setDateFrom,
    setDateTo,
  ]

  const count = useMemo(() => {
    let filtersCount = 0

    if (measurementIdFilter) filtersCount++
    if (userId) filtersCount++
    if (statusProtocol) filtersCount++
    if (approvedTransfer) filtersCount++
    if (approvedArshin) filtersCount++
    if (approvedFsa) filtersCount++
    if (dateFrom) filtersCount++
    if (dateTo) filtersCount++

    if (
      !measurementIdFilter &&
      !userId &&
      !statusProtocol &&
      !approvedTransfer &&
      !approvedArshin &&
      !approvedFsa &&
      !dateFrom &&
      !dateTo
    )
      filtersCount = 0
    return filtersCount
  }, [
    measurementIdFilter,
    userId,
    statusProtocol,
    approvedTransfer,
    approvedArshin,
    approvedFsa,
    dateFrom,
    dateTo,
  ])

  const countArchive = useMemo(() => {
    let filtersCount = 0

    if (measurementIdFilter) filtersCount++
    if (userId) filtersCount++
    if (dateFrom) filtersCount++
    if (dateTo) filtersCount++

    if (!measurementIdFilter && !userId && !dateFrom && !dateTo) filtersCount = 0
    return filtersCount
  }, [measurementIdFilter, userId, dateFrom, dateTo])

  const handleVerifiedAtChange = date => {
    if (date) {
      dispatch(setDateFrom(date.format('YYYY-MM-DD')))
    } else {
      dispatch(setDateFrom(null))
    }
  }

  const handleExpiredAtChange = date => {
    if (date) {
      dispatch(setDateTo(date.format('YYYY-MM-DD')))
    } else {
      dispatch(setDateTo(null))
    }
  }

  const changeMeasurementId = param => {
    dispatch(setMeasurementIdFilter(param))
  }

  const changeUserId = param => {
    dispatch(setUserId(param))
  }
  const changeApprovedStatus = event => {
    dispatch(setStatusId(event.target.value))
  }

  const changeApprovedTransfer = event => {
    dispatch(setApprovedTransfer(event.target.value))
  }

  const changeApprovedArshin = event => {
    dispatch(setApprovedArshin(event.target.value))
  }
  const changeApprovedFsa = event => {
    dispatch(setApprovedFsa(event.target.value))
  }

  useEffect(() => {
    if (approvedTransfer === 'no') {
      dispatch(setApprovedArshin(''))
    }

    if (approvedTransfer === 'no' || approvedArshin === 'not-published') {
      dispatch(setApprovedFsa(''))
    }
  }, [approvedTransfer, approvedArshin, dispatch])

  const handleResetFilter = () => {
    resetActions.forEach(action => dispatch(action('')))
  }

  const changeViewVariant = param => {
    handleResetFilter()
    dispatch(setViewVariantMain(param))
  }

  const filteredProtocolsData = protocolsData.filter(item => {
    if (viewVariant === 0) {
      return !item.archived_at
    } else if (viewVariant === 1) {
      return item.archived_at
    }
    return true
  })

  const noRowsText =
    viewVariant === 0
      ? 'Протоколы не созданы или перемещены в архив'
      : 'Архивные протоколы не найдены'

  const handleShowClick = param => {
    isMobile && navigate(`/metriva/protocols/${param.row.id}`)
  }
  const handleEditClick = protocolId => {
    navigate(`/metriva/protocols/${protocolId}/edit`)
  }
  const handleCopyClick = protocolId => {
    navigate(`/metriva/protocols/${protocolId}/copy`)
  }

  const handleDelete = async protocolId => {
    const { status } = await R.deleteProtocol(protocolId)
    if (status === 200) {
      dispatch(refreshProtocols())

      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Протокол удален'))
      dispatch(setOpen(false))
      dispatch(resetState())

      dispatch(refreshConditions())
      dispatch(refreshPersonalMetrolog())
      dispatch(refreshReceiveds())
      dispatch(refreshTickets())
      dispatch(refreshIssueCase())
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Ошибка обращения к API.'))
    }
  }

  const handleCheckProtocol = async () => {
    const data = {
      ids: selectedProtocols,
    }

    const { status } = await R.protocolCheck(data)

    if (status === 200) {
      dispatch(setMessage('Протоколы успешно одобрены для передачи в Аршин и ФСА'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(refreshProtocols())
      dispatch(resetState())
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Статус 500. Ошибка обращения к api.'))
    }
  }

  const handleClickOnArchiveBtn = () => {
    const filteredBySelected = filteredProtocolsData.filter(item =>
      selectedProtocols.includes(item.id),
    )

    const allThreeConditionsMet = filteredBySelected.every(
      item =>
        item.status === 'sent' &&
        item.status_arshin === 'published' &&
        item.status_fsa === 'published',
    )

    const someConditionsNotMet = filteredBySelected.some(
      item =>
        item.status !== 'sent' ||
        item.status_arshin !== 'published' ||
        item.status_fsa !== 'published',
    )

    const validProtocols = filteredBySelected.filter(
      item =>
        item.status === 'sent' &&
        item.status_arshin === 'published' &&
        item.status_fsa === 'published',
    )

    const updatedProtocols = validProtocols.map(item => item.id)

    let title
    let message
    let confirmAction

    if (allThreeConditionsMet) {
      title = 'Архивация протокола'
      message =
        'Вы действительно хотите перенести выбранные протоколы в архив?\nАрхивная версия будет доступна только в режиме просмотра.'
      confirmAction = () => handleArchiveProtocol(updatedProtocols)
    } else if (someConditionsNotMet && validProtocols.length > 0) {
      title = 'Архивация приостановлена'
      message =
        'Внимание! Архивация протоколов временно недоступна т.к. среди выбранных протоколов есть те, которые не были переданы во ФГИС Аршин и ФСА.\nВы можете продолжить, исключив данные протоколы из списка архивируемых, либо возобновить процесс позднее.'
      confirmAction = () => handleArchiveProtocol(updatedProtocols)
    } else {
      title = 'Архивация приостановлена'
      message =
        'Внимание! Архивация протоколов временно недоступна т.к. среди выбранных протоколов есть те, которые не были переданы во ФГИС Аршин и ФСА.'
      confirmAction = () => dispatch(setOpen(false))
    }

    dispatch(setOpen(true))
    dispatch(setTitle(title))
    dispatch(setChildren(message))
    dispatch(setBA('Архивировать'))
    dispatch(setBD('Закрыть'))
    dispatch(setConfirmAction(confirmAction))
    dispatch(refreshProtocols())
    if (validProtocols.length > 0) {
      dispatch(showBA(true))
    } else {
      dispatch(showBA(false))
    }
  }

  const handleArchiveProtocol = async updatedProtocols => {
    const data = {
      ids: updatedProtocols,
    }

    const { status } = await R.protocolArchive(data)

    if (status === 200) {
      dispatch(setMessage('Протоколы успешно архивированы.'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(refreshProtocols())
      dispatch(resetState())
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Статус 500. Ошибка обращения к api.'))
      dispatch(refreshProtocols())
    }
  }

  const handleUnArchiveProtocol = async () => {
    const data = {
      ids: selectedProtocols,
    }

    const { status } = await R.protocolUnArchive(data)

    if (status === 200) {
      dispatch(setMessage('Протоколы успешно восстановлены из архива.'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(refreshProtocols())
      dispatch(resetState())
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Статус 500. Ошибка обращения к api.'))
    }
  }

  const handlePdf = async () => {
    const data = {
      ids: selectedProtocols,
    }

    try {
      const { status, data: fileData } = await R.protocolPdf(data)
      const currentDate = new Date().toLocaleDateString('ru-RU').replace(/\./g, '-')

      if (status === 200) {
        let blob
        let fileName

        if (viewVariant === 0) {
          if (selectedProtocols.length === 1) {
            const currentProtocol = filteredProtocolsData.find(
              item => item.id === selectedProtocols[0],
            )
            const arshinId = currentProtocol?.arshin_id ?? 'Unknown'
            const verificationDate = moment(currentProtocol?.verification_date).format('DD-MM-YYYY')
            fileName = `Protocol_${arshinId}(${verificationDate}).pdf`
            blob = new Blob([fileData], { type: 'application/pdf' })
          } else {
            blob = new Blob([fileData], { type: 'application/zip' })
            fileName = `Protocoly_${currentDate}.zip`
          }
        } else {
          if (selectedProtocols.length === 1) {
            const currentProtocol = filteredProtocolsData.find(
              item => item.id === selectedProtocols[0],
            )
            const arshinId = currentProtocol?.arshin_id ?? 'Unknown'
            const verificationDate = moment(currentProtocol?.verification_date).format('DD-MM-YYYY')
            fileName = `Protocol_(arhive)_${arshinId}(${verificationDate}).pdf`
            blob = new Blob([fileData], { type: 'application/pdf' })
          } else {
            blob = new Blob([fileData], { type: 'application/zip' })
            fileName = `Protocoly_(arhive)_${currentDate}.zip`
          }
        }

        const url = URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.href = url
        link.download = fileName
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        URL.revokeObjectURL(url)

        dispatch(setMessage('Протокол(ы) успешно скачан(ы) в PDF.'))
        dispatch(setType('success'))
        dispatch(setOpenAlert(true))
        dispatch(refreshProtocols())
        dispatch(resetState())
      } else if (status === 404) {
        dispatch(setOpen(true))
        dispatch(setTitle('Cкачивание PDF недоступно'))
        dispatch(
          setChildren(
            selectedProtocols.length === 1 ? (
              <span>
                Внимание! Скачивание PDF недоступно, т.к. выбранный протокол не был опубликован во
                ФГИС Аршин
              </span>
            ) : (
              <span>
                Внимание! Скачивание PDF недоступно, т.к. выбранные протоколы не были опубликованы
                во ФГИС Аршин
              </span>
            ),
          ),
        )
        dispatch(showBA(false))
        dispatch(setBD('Закрыть'))
      }
    } catch (error) {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Ошибка при обращении к API.'))
    }
  }

  const activeColData = [
    {
      field: 'uniqueNumber',
      headerName: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          Уникальный номер
          <Tooltip
            title={
              'Уникальный номер протокола совпадает с числовым номером, который присваивается ему при публикации в Аршин'
            }
            style={{ marginLeft: '7px' }}
          >
            <InfoCircle />
          </Tooltip>
        </div>
      ),
    },
    {
      field: 'verificationDate',
      headerName: 'Дата поверки',
    },
    {
      field: 'totalAccepted',
      headerName: 'Заключение',
    },
    userRole === 'metrologist'
      ? {
          field: 'measurement',
          headerName: 'Область измерения',
        }
      : {
          field: 'createdBy',
          headerName: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              Поверитель
              <Tooltip
                title={'Фактический поверитель - пользователь который создал протокол в системе'}
                style={{ marginLeft: '7px' }}
              >
                <InfoCircle />
              </Tooltip>
            </div>
          ),
        },
    {
      field: 'status',
      headerName: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          Одобрен к передаче
          <Tooltip
            title={
              isTrusted
                ? 'Для одобрения передачи: выберите необходимые протоколы в общем списке и нажмите кнопку "Передать в Аршин и ФСА" или предоставьте разрешение в процессе создания или редактирования протокола'
                : ' Дождитесь когда передача протоколов во ФГИС Аршин будет одобрена руководителем или администратором'
            }
            style={{ marginLeft: '7px' }}
          >
            <InfoCircle />
          </Tooltip>
        </div>
      ),
      renderCell: params => {
        const { status } = params.row

        if (status === 'sent') {
          return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <CheckCircleIcon />
            </div>
          )
        } else {
          return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <ExitIcon />
            </div>
          )
        }
      },
    },
    {
      field: 'statusArshin',
      headerName: <div style={{ display: 'flex', alignItems: 'center' }}>Передача в Аршин</div>,
      renderCell: params => {
        const { statusArshin } = params.row

        if (statusArshin === 'not-sent') {
          return <p style={{ color: '#E74953' }}>Не начата</p>
        } else if (statusArshin === 'sent') {
          return <p style={{ color: '#E57004' }}>В процессе</p>
        } else if (statusArshin === 'published') {
          return <p style={{ color: '#00A37C' }}>Завершена</p>
        }
      },
    },
    {
      field: 'statusFsa',
      headerName: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          Передача в ФСА
          <Tooltip
            title='Передача начнётся автоматически после получения данных о номере свидетельства или извещения из ФГИС Аршин'
            style={{ marginLeft: '7px' }}
          >
            <InfoCircle />
          </Tooltip>
        </div>
      ),
      renderCell: params => {
        const { statusFsa } = params.row

        if (statusFsa === 'not-sent') {
          return <p style={{ color: '#E74953' }}>Не начата</p>
        } else if (statusFsa === 'sent') {
          return <p style={{ color: '#E57004' }}>В процессе</p>
        } else if (statusFsa === 'published') {
          return <p style={{ color: '#00A37C' }}>Завершена</p>
        }
      },
    },
    {
      field: 'certificate',
      headerName: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          № свидетельства или извещения
          <Tooltip title={'Приходит из ФГИС «Аршин»'} style={{ marginLeft: '7px' }}>
            <InfoCircle />
          </Tooltip>
        </div>
      ),
    },
    {
      field: 'arshinId',
      headerName: 'Публикация данных о поверке СИ',
      renderCell: params => {
        const { arshinId } = params.row

        if (arshinId !== 'Еще не присвоен') {
          return (
            <a
              href={`https://fgis.gost.ru/fundmetrology/cm/results/1-${arshinId}`}
              target='_blank'
              style={{ textDecoration: 'none' }}
              onClick={event => event.stopPropagation()}
              rel='noreferrer'
            >
              <Typography
                variant='subtitle2'
                color={theme.palette.text.secondary}
                sx={{
                  cursor: 'pointer',
                  borderBottom: `1px solid ${theme.palette.text.secondary}`,
                  fontWeight: '500 !important',
                }}
              >
                Ссылка на ФГИС Аршин
              </Typography>
            </a>
          )
        } else {
          return (
            <Typography variant='subtitle2' color={'#899298'} sx={{ fontWeight: '500 !important' }}>
              Ссылка на ФГИС Аршин
            </Typography>
          )
        }
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Действия',
      getActions: item => {
        const localItem = item

        return [
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            {typeof localItem.row.originalCase === 'object' &&
            localItem.row.originalCase !== null ? (
              <Tooltip title='Копировать протокол'>
                <div
                  style={{ cursor: 'pointer', marginRight: '2px' }}
                  onClick={() => handleCopyClick(localItem.id)}
                >
                  <CopyIcon />
                </div>
              </Tooltip>
            ) : (
              <Tooltip title='Копирование протокола недоступно, используемый комплект СП был удалён или изменён'>
                <div style={{ marginRight: '2px' }} onClick={() => {}}>
                  <CopyIcon color='#BDC1C4' />
                </div>
              </Tooltip>
            )}

            {localItem.row.status !== 'sent' ? (
              <Tooltip title='Редактировать протокол'>
                <div style={{ cursor: 'pointer' }} onClick={() => handleEditClick(localItem.id)}>
                  <PencilIcon />
                </div>
              </Tooltip>
            ) : (
              <Tooltip title='Редактирование недоступно, протокол уже одобрен к передаче в Аршин и ФСА'>
                <PencilIcon color='#BDC1C4' />
              </Tooltip>
            )}
            {userRole === 'super' || userRole === 'admin' ? (
              localItem.row.status !== 'sent' ? (
                <Tooltip title='Удалить протокол'>
                  <div
                    onClick={() => {
                      dispatch(setOpen(true))
                      dispatch(setTitle('Вы действительно хотите удалить протокол?'))
                      dispatch(setChildren('Отменить действие будет уже невозможно.'))
                      dispatch(setBA('Удалить'))
                      dispatch(setConfirmAction(() => handleDelete(localItem.id)))
                    }}
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    <DeleteIcon />
                  </div>
                </Tooltip>
              ) : (
                <Tooltip title='Удаление недоступно, протокол уже одобрен к передаче в Аршин и ФСА'>
                  <DeleteIcon color='#BDC1C4' />
                </Tooltip>
              )
            ) : null}
          </div>,
        ]
      },
    },
  ]

  const archiveColData = [
    {
      field: 'uniqueNumber',
      headerName: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          Уникальный номер
          <Tooltip
            title={
              'Уникальный номер протокола совпадает с числовым номером, который присваивается ему при публикации в Аршин'
            }
            style={{ marginLeft: '7px' }}
          >
            <InfoCircle />
          </Tooltip>
        </div>
      ),
    },
    {
      field: 'verificationDate',
      headerName: 'Дата поверки',
    },
    {
      field: 'totalAccepted',
      headerName: 'Заключение',
    },
    userRole === 'metrologist'
      ? {
          field: 'measurement',
          headerName: 'Область измерения',
        }
      : {
          field: 'createdBy',
          headerName: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              Поверитель
              <Tooltip
                title={'Фактический поверитель - пользователь который создал протокол в системе'}
                style={{ marginLeft: '7px' }}
              >
                <InfoCircle />
              </Tooltip>
            </div>
          ),
        },

    {
      field: 'arshinId',
      headerName: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          № свидетельства или извещения
          <Tooltip title={'Приходит из ФГИС Аршин'} style={{ marginLeft: '7px' }}>
            <InfoCircle />
          </Tooltip>
        </div>
      ),
    },
  ]

  const ActiveFilters = () => (
    <RightSideContainer blockTitle={'Фильтры'}>
      <div className='right-side-container'>
        <div className='right-side-container-block'>
          <span
            style={{
              display: 'block',
              width: '100%',
              fontSize: '15px',
              fontWeight: 600,
            }}
          >
            Область измерения
          </span>
          <div style={{ width: '100%' }}>
            <FilterMeasurement
              measurementId={measurementIdFilter}
              setMeasurementId={changeMeasurementId}
              isRequired={false}
            />
          </div>
        </div>

        {userRole !== 'metrologist' && (
          <div className='right-side-container-block'>
            <span
              style={{
                display: 'block',
                width: '100%',
                fontSize: '15px',
                fontWeight: 600,
              }}
            >
              Поверитель
            </span>
            <div style={{ width: '100%' }}>
              <FilterUsers userId={userId} setUserId={changeUserId} />
            </div>
          </div>
        )}

        <div className='right-side-container-block'>
          <span
            style={{
              display: 'block',
              width: '100%',
              fontSize: '15px',
              fontWeight: 600,
            }}
          >
            Заключение поверки
          </span>
          <div style={{ width: '100%' }}>
            <RadioButton
              value={statusProtocol}
              onChange={changeApprovedStatus}
              trueLabel={'Пригоден'}
              falseLabel={'Непригоден'}
              trueValue={true}
              falseValue={false}
            />
          </div>
        </div>

        <div className='right-side-container-block'>
          <span
            style={{
              display: 'block',
              width: '100%',
              fontSize: '15px',
              fontWeight: 600,
            }}
          >
            Одобрен к передаче
          </span>
          <div style={{ width: '100%' }}>
            <RadioButton
              value={approvedTransfer}
              onChange={changeApprovedTransfer}
              trueLabel={'Да'}
              falseLabel={'Нет'}
              trueValue={'yes'}
              falseValue={'no'}
            />
          </div>
        </div>

        <Collapse in={approvedTransfer === 'yes'} timeout={500} unmountOnExit>
          <div className='right-side-container-block'>
            <span
              style={{
                display: 'block',
                width: '100%',
                fontSize: '15px',
                fontWeight: 600,
              }}
            >
              Передан в Аршин
            </span>
            <div style={{ width: '100%' }}>
              <RadioButton
                value={approvedArshin}
                onChange={changeApprovedArshin}
                trueLabel={'Да'}
                falseLabel={'Нет'}
                trueValue={'published'}
                falseValue={'not-published'}
              />
            </div>
          </div>
        </Collapse>

        <Collapse
          in={approvedTransfer === 'yes' && approvedArshin === 'published'}
          timeout={500}
          unmountOnExit
        >
          <div className='right-side-container-block'>
            <span
              style={{
                display: 'block',
                width: '100%',
                fontSize: '15px',
                fontWeight: 600,
              }}
            >
              Передан в ФСА
            </span>
            <div style={{ width: '100%' }}>
              <RadioButton
                value={approvedFsa}
                onChange={changeApprovedFsa}
                trueLabel={'Да'}
                falseLabel={'Нет'}
                trueValue={'published'}
                falseValue={'not-published'}
              />
            </div>
          </div>
        </Collapse>

        <div className='right-side-container-block'>
          <span
            style={{
              display: 'block',
              width: '100%',
              fontSize: '15px',
              fontWeight: 600,
            }}
          >
            Дата поверки
          </span>
          <DatePicker selectedDate={dateFrom} dateChange={handleVerifiedAtChange} />
          <DatePicker selectedDate={dateTo} dateChange={handleExpiredAtChange} />
        </div>

        <Button
          onClick={handleResetFilter}
          label='Сбросить все значения'
          color='secondary'
          fullWidth
        />
      </div>
    </RightSideContainer>
  )

  const ArchiveFilters = () => (
    <RightSideContainer blockTitle={'Фильтры'}>
      <div className='right-side-container'>
        <div className='right-side-container-block'>
          <span
            style={{
              display: 'block',
              width: '100%',
              fontSize: '15px',
              fontWeight: 600,
            }}
          >
            Область измерения
          </span>
          <div style={{ width: '100%' }}>
            <FilterMeasurement
              measurementId={measurementIdFilter}
              setMeasurementId={changeMeasurementId}
              isRequired={false}
            />
          </div>
        </div>

        {userRole !== 'metrologist' && (
          <div className='right-side-container-block'>
            <span
              style={{
                display: 'block',
                width: '100%',
                fontSize: '15px',
                fontWeight: 600,
              }}
            >
              Поверитель
            </span>
            <div style={{ width: '100%' }}>
              <FilterUsers userId={userId} setUserId={changeUserId} />
            </div>
          </div>
        )}

        <div className='right-side-container-block'>
          <span
            style={{
              display: 'block',
              width: '100%',
              fontSize: '15px',
              fontWeight: 600,
            }}
          >
            Заключение поверки
          </span>
          <div style={{ width: '100%' }}>
            <RadioButton
              value={statusProtocol}
              onChange={changeApprovedStatus}
              trueLabel={'Пригоден'}
              falseLabel={'Непригоден'}
              trueValue={true}
              falseValue={false}
            />
          </div>
        </div>

        <div className='right-side-container-block'>
          <span
            style={{
              display: 'block',
              width: '100%',
              fontSize: '15px',
              fontWeight: 600,
            }}
          >
            Дата поверки
          </span>
          <div style={{ width: '100%' }}>
            <DatePicker selectedDate={dateFrom} dateChange={handleVerifiedAtChange} />
          </div>
          <div style={{ width: '100%' }}>
            <DatePicker selectedDate={dateTo} dateChange={handleExpiredAtChange} />
          </div>
        </div>

        <Button
          onClick={handleResetFilter}
          label='Сбросить все значения'
          color='secondary'
          fullWidth
        />
      </div>
    </RightSideContainer>
  )

  const hasProtocolsWithoutArshinId = selectedProtocols.some(protocolId =>
    filteredProtocolsData.some(
      item =>
        item.id === protocolId && (item.arshin_id === 'Еще не присвоен' || item.arshin_id === null),
    ),
  )

  const hasProtocolsWithoutArshinIdAll = selectedProtocols.every(protocolId =>
    filteredProtocolsData
      .filter(item => item.id === protocolId)
      .every(item => item.arshin_id === 'Еще не присвоен'),
  )

  const DinamicActions = () => (
    <div
      style={{
        display: 'flex',
        flexFlow: 'row',
        alignItems: 'center',
        height: '100%',
        gap: '10px',
      }}
    >
      {selectedProtocols.length > 1 && hasProtocolsWithoutArshinIdAll ? (
        <Button
          onClick={() => {
            dispatch(setOpen(true))
            dispatch(setTitle('Cкачивание PDF недоступно'))
            dispatch(
              setChildren(
                'Внимание! Скачивание PDF недоступно, т.к. выбранные протоколы не были опубликованы во ФГИС Аршин',
              ),
            )
            dispatch(showBA(false))
            dispatch(setBD('Закрыть'))
          }}
          label='Скачать PDF'
          color='text'
          startIcon={<PdfIcon color={theme.palette.text.dark} />}
          styles={{
            padding: 0,
            color: 'inherit',
            minWidth: '50px',
            paddingLeft: '18px',
            paddingRight: '14px',
            marginLeft: '-10px',
            fontSize: '.88rem',
          }}
        />
      ) : selectedProtocols.length > 1 && hasProtocolsWithoutArshinId ? (
        <Button
          onClick={() => {
            dispatch(setOpen(true))
            dispatch(setTitle('Частичное скачивание PDF'))
            dispatch(setBA('Скачать'))
            dispatch(
              setChildren(
                'Внимание! Протоколы у которых отсутствуют уникальные номера недоступны для скачивания в нередактируемом формате PDF.  Вы можете продолжить скачивание без них или возобновить процесс позднее.',
              ),
            )
            dispatch(setConfirmAction(handlePdf))
          }}
          label='Скачать PDF'
          color='text'
          startIcon={<PdfIcon color={theme.palette.text.dark} />}
          styles={{
            padding: 0,
            color: 'inherit',
            minWidth: '50px',
            paddingLeft: '18px',
            paddingRight: '14px',
            marginLeft: '-10px',
            fontSize: '.88rem',
          }}
        />
      ) : (
        <Button
          onClick={handlePdf}
          label='Скачать PDF'
          color='text'
          startIcon={<PdfIcon color={theme.palette.text.dark} />}
          styles={{
            padding: 0,
            color: 'inherit',
            minWidth: '50px',
            paddingLeft: '18px',
            paddingRight: '14px',
            marginLeft: '-10px',
            fontSize: '.88rem',
          }}
        />
      )}

      {userRole !== 'metrologist' && (
        <React.Fragment>
          <span
            style={{
              width: '1px',
              height: '100%',
              opacity: 0.44,
              background: 'linear-gradient(to bottom, transparent, gray, transparent)',
            }}
          />
          <Button
            onClick={handleCheckProtocol}
            label='Передать в Аршин и ФСА'
            color='text'
            startIcon={<ExportIcon color={theme.palette.text.dark} />}
            // ----------------------------------------------------------------
            // startIcon={<ArchiveIcon color={theme.palette.text.dark} />}
            // ----------------------------------------------------------------
            styles={{
              padding: 0,
              color: 'inherit',
              minWidth: '50px',
              paddingLeft: '18px',
              paddingRight: '14px',
              fontSize: '.88rem',
            }}
          />
        </React.Fragment>
      )}
      {(userRole === 'super' || userRole === 'admin') && (
        <React.Fragment>
          <span
            style={{
              width: '1px',
              height: '100%',
              opacity: 0.44,
              background: 'linear-gradient(to bottom, transparent, gray, transparent)',
            }}
          />
          <Button
            onClick={handleClickOnArchiveBtn}
            label='Архивировать'
            color='text'
            startIcon={<ArchiveIcon color={theme.palette.text.dark} />}
            styles={{
              padding: 0,
              color: 'inherit',
              minWidth: '50px',
              paddingLeft: '18px',
              paddingRight: '14px',
              fontSize: '.88rem',
            }}
          />
        </React.Fragment>
      )}
    </div>
  )

  const DinamicActionsArchive = () => (
    <div
      style={{
        display: 'flex',
        flexFlow: 'row',
        alignItems: 'center',
        height: '100%',
        gap: '10px',
      }}
    >
      <Button
        onClick={handlePdf}
        label='Скачать PDF'
        color='text'
        startIcon={<PdfIcon color={theme.palette.text.dark} />}
        styles={{
          padding: 0,
          color: 'inherit',
          minWidth: '50px',
          paddingLeft: '18px',
          paddingRight: '14px',
          marginLeft: '-10px',
          fontSize: '.88rem',
        }}
      />

      {(userRole === 'super' || userRole === 'admin') && (
        <React.Fragment>
          <span
            style={{
              width: '1px',
              height: '100%',
              opacity: 0.44,
              background: 'linear-gradient(to bottom, transparent, gray, transparent)',
            }}
          />
          <Button
            onClick={() => {
              dispatch(setOpen(true))

              dispatch(setTitle('Вы действительно хотите востановить выбранные протоколы?'))
              dispatch(
                setChildren(
                  "После восстановления, выбранные протоколы будут перенесены во вкладку 'Актуальные'.",
                ),
              )
              dispatch(setBA('Восстановить'))
              dispatch(setConfirmAction(() => handleUnArchiveProtocol()))
            }}
            label='Восстановить'
            color='text'
            startIcon={<RestoreIcon color={theme.palette.text.dark} />}
            styles={{
              padding: 0,
              color: 'inherit',
              minWidth: '50px',
              paddingLeft: '18px',
              paddingRight: '14px',
              fontSize: '.88rem',
            }}
          />
        </React.Fragment>
      )}
    </div>
  )

  return (
    <main className='main'>
      {userRole === 'super' && (
        <div
          style={{ width: isMobile ? '605px' : '100%', marginBottom: !companyId ? '0px' : '10px' }}
        >
          <FilterCompany />
        </div>
      )}

      {!companyId ? (
        <div className='page_null'>
          <Typography variant='body1'>Для просмотра данных выберите компанию</Typography>
        </div>
      ) : (
        <>
          {(userRole === 'super' || userRole === 'admin') && (
            <BasicTabs
              viewVariant={viewVariant}
              callback={changeViewVariant}
              tabs={[{ label: 'Актуальные' }, { label: 'Архив' }]}
            />
          )}

          {viewVariant === 0 ? (
            <React.Fragment>
              <ActiveFilters />
              <Table
                colData={activeColData}
                rowData={filteredProtocolsData.map(item => ({
                  id: item.id,
                  uniqueNumber: item.arshin_id ?? '-',
                  verificationDate: moment(item.verification_date).format('DD-MM-YYYY'),
                  measurement: item.measurement?.name,
                  createdBy: `${item.createdBy?.surname || ''} ${item.createdBy?.name || ''}`,
                  totalAccepted: item.total_accepted ? 'Пригоден' : 'Непригоден',
                  status: item.status,
                  statusArshin: item.status_arshin,
                  statusFsa: item.status_fsa,
                  certificate: item.certificate ?? '-',
                  arshinId: item.arshin_id,
                  originalCase: item.originalCase,
                }))}
                rowCallback={handleShowClick}
                addButtonCallback={() => {}}
                showToolbar={true}
                noCreating={true}
                actions={{
                  static: <Csv />,
                  dinamic:
                    selectedProtocols && selectedProtocols.length > 0 ? <DinamicActions /> : null,
                }}
                filtersVariant={'server'}
                setIsDrawerOpenServer={() => {
                  dispatch(setOpenRightContainer(true))
                }}
                rowSelectionCallback={array => {
                  dispatch(setSelectedProtocols(array))
                }}
                noRowsText={noRowsText}
                hasCheckboxSelection={true}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <ArchiveFilters />
              <Table
                colData={archiveColData}
                rowData={filteredProtocolsData.map(item => ({
                  id: item.id,
                  uniqueNumber: item.arshin_id ?? '-',
                  verificationDate: moment(item.verification_date).format('DD-MM-YYYY'),
                  measurement: item.measurement?.name ?? '-',
                  createdBy: `${item.createdBy?.surname ?? ''} ${item.createdBy?.name ?? ''}`,
                  totalAccepted: item.total_accepted ? 'Пригоден' : 'Непригоден',
                  arshinId: item.certificate ?? '-',
                }))}
                rowCallback={handleShowClick}
                showToolbar={true}
                noCreating={true}
                actions={{
                  static: <Csv />,
                  dinamic:
                    selectedProtocols && selectedProtocols.length > 0 ? (
                      <DinamicActionsArchive />
                    ) : null,
                }}
                filtersVariant={'server'}
                setIsDrawerOpenServer={() => {
                  dispatch(setOpenRightContainer(true))
                }}
                rowSelectionCallback={array => {
                  dispatch(setSelectedProtocols(array))
                }}
                noRowsText={noRowsText}
                hasCheckboxSelection={true}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
              />
            </React.Fragment>
          )}
        </>
      )}
    </main>
  )
}

export default AllProtocols
