import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import AccordionComponent from '../../../components/LocalElements/Accordion/Accordion.jsx'
import { Checkbox, CircularProgress } from '@mui/material'
import Table from '../../LocalElements/Table/Table'
import Input from '../../../../ui/input/index.tsx'
import DatePicker from '../../LocalElements/DatePicker/DatePicker'
import Button from '../../../../ui/button/index.tsx'
import { refreshCompanies } from '../../../../store/slices/app/controlers/updater'
import R from '../../../../services/app/client-server/request.service'
import {
  setOpen,
  setTitle,
  setChildren,
  setBD,
  showBA,
} from '../../../../store/slices/app/comps/modal'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../../store/slices/app/comps/snackbar'

const Trial = props => {
  const { companyData, rates, subscription } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const companyId = useSelector(state => state.companiesReducer.companyId)
  const statusSelector = useSelector(state => state.companiesReducer.statusSelector)

  const [tariffInclusion, seTariffInclusion] = useState([])
  const [tariffIds, setTariffIds] = useState([])

  const [priceForOrder1, setPriceForOrder1] = useState('')

  useEffect(() => {
    if (!companyData?.tariffs || !Array.isArray(companyData.tariffs)) {
      return
    }
    const companyTariffIds = companyData.tariffs.map(tariff => tariff.id)
    const initialInclusion = rates.map(rate => ({
      tariff_id: rate.tariff_id,
      is_required: companyTariffIds.includes(rate.tariff_id) ? true : rate.is_required || false,
    }))
    seTariffInclusion(initialInclusion)
  }, [rates, companyData?.tariffs])

  useEffect(() => {
    const updatedRates = rates.reduce((acc, current) => {
      const existingRate = acc.find(item => item.tariff_id === current.tariff_id)
      if (!existingRate) {
        const matchingState = tariffInclusion.find(state => state.tariff_id === current.tariff_id)

        acc.push({
          tariff_id: current.tariff_id,
          is_required: matchingState ? matchingState.is_required : current.is_required,
        })
      }
      return acc
    }, [])

    setTariffIds(updatedRates)
  }, [rates, tariffInclusion])

  const handleCheckboxChange = (tariffId, isRequired, checked) => {
    seTariffInclusion(prevState => {
      const existingIndex = prevState.findIndex(item => item.tariff_id === tariffId)
      if (existingIndex > -1) {
        const updatedState = [...prevState]
        updatedState[existingIndex] = { tariff_id: tariffId, is_required: checked }
        return updatedState
      } else {
        return [...prevState, { tariff_id: tariffId, is_required: checked }]
      }
    })
  }

  let colDataWithoutActions = [
    {
      field: 'service',
      headerName: 'Услуга',
    },
    {
      field: 'description',
      headerName: 'Описание',
    },
    {
      field: 'billing',
      headerName: 'Биллинг-единица',
      renderCell: params => {
        const { value } = params

        const billingText =
          value === 'Протокол' ? `${value} <span style="color: #0084e2">*</span>` : value
        return <div dangerouslySetInnerHTML={{ __html: billingText }} />
      },
    },
    {
      field: 'count',
      headerName: 'Количество единиц',
    },
    {
      field: 'prise',
      headerName: 'Стоимость, ₽ (за единицу)',
      renderCell: params => {
        const { row } = params
        const countBillingValue = row.billing

        let priceToShow = row.prise

        if (row.threshold !== null && row.threshold !== undefined) {
          switch (true) {
            case countBillingValue >= 501 && countBillingValue <= 1000:
              priceToShow = '13.50'
              break
            case countBillingValue >= 1001 && countBillingValue <= 1500:
              priceToShow = '12.00'
              break
            case countBillingValue >= 1501 && countBillingValue <= 2000:
              priceToShow = '10.50'
              break
            case countBillingValue >= 2001 && countBillingValue <= 3000:
              priceToShow = '9.00'
              break
            case countBillingValue >= 3001 && countBillingValue <= 5000:
              priceToShow = '7.50'
              break
            case countBillingValue >= 5001 && countBillingValue <= 7000:
              priceToShow = '5.25'
              break
            case countBillingValue >= 7001:
              priceToShow = '4.00'
              break
            default:
              priceToShow = row.prise
          }
        }

        return (
          <div>
            {priceToShow}
            {row.threshold !== null && row.threshold !== undefined && (
              <span style={{ color: '#0084e2' }}> **</span>
            )}
          </div>
        )
      },
    },
    {
      field: 'priseForService',
      headerName: 'Стоимость услуги, ₽(за расчетный период)',
      renderCell: params => {
        const { row } = params

        if (row.id === 1) {
          return (
            <Input
              type='text'
              placeholder={'Сумма не указана'}
              value={priceForOrder1}
              actions={{
                change: value => setPriceForOrder1(value),
              }}
              sx={{ height: '44px', border: 'none' }}
            />
          )
        }

        return <div>{row.priseForService}</div>
      },
    },
  ]

  let colDataWithActions = [
    ...colDataWithoutActions,
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Включить',
      renderCell: params => {
        const tariffId = params.row.tariff_id
        const isRequired = params.row.is_required
        const currentState = tariffInclusion.find(item => item.tariff_id === tariffId)
        return (
          <div style={{ display: 'flex', flexFlow: 'row' }}>
            <Checkbox
              disabled={isRequired}
              checked={isRequired || currentState?.is_required || false}
              onChange={e => handleCheckboxChange(tariffId, isRequired, e.target.checked)}
            />
          </div>
        )
      },
    },
  ]

  const handleSave = async () => {
    const tariffs = tariffIds.filter(rate => rate.is_required).map(rate => rate.tariff_id)

    const data = {
      tariffs,
    }

    const response = await R.tariffChange(data, companyId)

    const { status, data: tariffsResponse } = response

    if (status === 200) {
      dispatch(setMessage(tariffsResponse.message || 'Изменения успешно сохранены.'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(refreshCompanies())
      navigate('/metriva/companies')
    } else {
      dispatch(setMessage(tariffsResponse.message))
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
    }
  }

  return (
    <React.Fragment>
      <h2 style={{ marginBottom: '26px', fontWeight: 'bold', marginTop: '16px' }}>
        Тариф «{subscription[0]?.title}»
      </h2>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <AccordionComponent
          title='Краткое описание'
          dataType='custom'
          backgroundColor='white'
          data={subscription[0]?.description}
          isExpanded={false}
        />

        <AccordionComponent
          title='Расчетный период'
          dataType='custom'
          backgroundColor='white'
          data={
            <div
              style={{
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '24px',
                background: '#EAEDF3',
                borderRadius: '12px',
                margin: '0 12px',
                padding: '18px',
              }}
            >
              <h4 style={{ marginBottom: '24px', paddingLeft: '12px' }}>Текущий период</h4>

              <div style={{ display: 'flex', gap: '16px' }}>
                <DatePicker
                  label={'Начало'}
                  isDisabled={true}
                  selectedDate={
                    companyData?.created_at?.full ? dayjs(companyData.created_at.full) : null
                  }
                />
                <DatePicker
                  label={'Конец (включительно)'}
                  isDisabled={true}
                  selectedDate={
                    companyData?.activation_date
                      ? dayjs(companyData.activation_date)
                      : companyData?.created_at?.full
                      ? dayjs(companyData.created_at.full).add(1, 'month').subtract(1, 'day')
                      : null
                  }
                />
              </div>
            </div>
          }
          isExpanded={true}
        />

        <AccordionComponent
          title='Набор услуг (общая стоимость)'
          dataType='custom'
          backgroundColor='white'
          data={
            <div
              style={{
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '24px',
              }}
            >
              <h4 style={{ marginBottom: '15px' }}>Расчет предоплаты по лицензии</h4>
              <Table
                colData={colDataWithoutActions}
                rowData={rates
                  .filter(item => !item.post_payment && ![2, 3, 4].includes(item.order))
                  .map(item => ({
                    id: item?.order,
                    service: item?.title,
                    description: item?.description,
                    billing: item?.units,
                    count: '1',
                    prise: item?.price,
                    priseForService: item?.price,
                    threshold: item?.threshold,
                    is_required: item?.is_required,
                  }))}
                hideFooter={true}
                hasCheckboxSelection={false}
                noRowsText={<CircularProgress />}
                getRowId={row => row.id}
                getRowHeight={() => 'auto'}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 24,
                    },
                  },
                }}
              />
            </div>
          }
          isExpanded={true}
        />

        <AccordionComponent
          title='Набор услуг (постоплата)'
          dataType='custom'
          backgroundColor='white'
          data={
            <div
              style={{
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '24px',
              }}
            >
              <h4 style={{ marginRight: '20px' }}>Выбор основных опций</h4>
              <Table
                rowData={rates
                  .filter(item => item.post_payment)
                  .map(item => ({
                    id: item?.order,
                    service: item?.title,
                    description: item?.description,
                    billing: item?.units,
                    count: 'Неограниченно',
                    prise: item?.price,
                    priseForService: item?.price,
                    threshold: item?.threshold,
                    is_required: item?.is_required,
                    tariff_id: item?.tariff_id,
                  }))}
                colData={colDataWithActions}
                hideFooter={true}
                hasCheckboxSelection={false}
                getRowHeight={() => 'auto'}
                noRowsText={<CircularProgress />}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 24,
                    },
                  },
                }}
              />
              <div style={{ marginTop: '16px' }}>
                <p style={{ paddingLeft: '10px' }}>
                  <span style={{ color: '#0084e2' }}>*</span> - тарифицируются только протоколы
                  отправленные во ФГИС Аршин и ФСА
                </p>
                <p style={{ paddingLeft: '10px' }}>
                  <span style={{ color: '#0084e2' }}>**</span> - стоимость может меняться в
                  зависимости от числа поверок{' '}
                  <span
                    style={{
                      color: '#0084e2',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      dispatch(setOpen(true))
                      dispatch(setTitle('Стоимость может меняться в зависимости от числа поверок'))

                      dispatch(
                        setChildren(
                          <div>
                            <p style={{ marginBottom: '8px' }}>
                              До 500 поверок в месяц - 15,00 ₽/шт.
                            </p>
                            <p style={{ marginBottom: '8px' }}>
                              От 501 до 1000 поверок в месяц - 13,50 ₽/шт.
                            </p>
                            <p style={{ marginBottom: '8px' }}>
                              От 1001 до 1500 поверок в месяц - 12,00 ₽/шт.
                            </p>
                            <p style={{ marginBottom: '8px' }}>
                              От 1501 до 2000 поверок в месяц - 10,50 ₽/шт.
                            </p>
                            <p style={{ marginBottom: '8px' }}>
                              От 2001 до 3000 поверок в месяц - 9,00 ₽/шт.
                            </p>
                            <p style={{ marginBottom: '8px' }}>
                              От 3001 до 5000 поверок в месяц - 7,50 ₽/шт.
                            </p>
                            <p style={{ marginBottom: '8px' }}>
                              От 5001 до 7000 поверок в месяц - 5,25 ₽/шт.
                            </p>
                            <p>Более 7001 поверок в месяц - 4,00 ₽/шт.</p>
                          </div>
                        )
                      )
                      dispatch(setBD('Cкрыть'))
                      dispatch(showBA(false))
                    }}
                  >
                    см. подробнее
                  </span>
                </p>
              </div>
            </div>
          }
          isExpanded={true}
        />

        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <h4 style={{ color: '#132532' }}>Действие тарифа:</h4>
            <h4 style={{ color: '#0084e2' }}>
              {statusSelector === 'trial'
                ? `Действует до ${
                    companyData?.activation_date
                      ? dayjs(companyData.activation_date).format('DD.MM.YYYY')
                      : companyData?.created_at?.full
                      ? dayjs(companyData.created_at.full)
                          .add(1, 'month')
                          .subtract(1, 'day')
                          .format('DD.MM.YYYY')
                      : null
                  }`
                : 'Завершен'}
            </h4>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <h4 style={{ color: '#132532' }}>Общая стоимость, ₽:</h4>
            <h4 style={{ color: '#0084e2' }}>-</h4>
          </div>

          <Button
            onClick={handleSave}
            color='primary'
            label='Сохранить изменения'
            fullWidth
            disabled={statusSelector !== 'trial'}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Trial
