import React from 'react'
import { Typography } from '@mui/material'
import theme from '../../../../../utils/theme.ts'
import { TotalAcceptedTrue, TotalAcceptedFalse } from '../../styles.ts'

const TotalAccepted = props => {
  const { value } = props

  return (
    <React.Fragment>
      {value ? (
        <TotalAcceptedTrue>
          <Typography variant='h2' color={theme.palette.text.secondary}>
            Заключение поверки
          </Typography>
          <Typography variant='subtitle2' color={theme.palette.text.secondary}>
            На основании результатов поверки, средство измерения признано пригодным к использованию.
          </Typography>
        </TotalAcceptedTrue>
      ) : (
        <TotalAcceptedFalse>
          <Typography variant='h2' color={theme.palette.background.error}>
            Заключение поверки
          </Typography>
          <Typography variant='subtitle2' color={theme.palette.background.error}>
            На основании результатов поверки, средство измерения признано непригодным к
            использованию.
          </Typography>
          <Typography
            variant='subtitle2'
            color={theme.palette.background.error}
            sx={{ opacity: '0.8' }}
          >
            *Статус заключения зависит от результатов внешнего осмотра, опробования, определения
            относительной погрешности.
          </Typography>
        </TotalAcceptedFalse>
      )}
    </React.Fragment>
  )
}

export default TotalAccepted
