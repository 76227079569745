// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../ui/button/index.tsx'

import {
  changeMainData,
  changeOverData,
  resetCreateData,
} from '../../../store/slices/app/views/companies'
import { refreshCompanies, refreshHeader } from '../../../store/slices/app/controlers/updater'

import NameCompany from '../../components/Companies/NameCompany'
import AddressCompany from '../../components/Companies/AddressCompany'
import LogoCompany from '../../components/Companies/EditLogoCompany'
import CompanyBank from '../../components/Companies/CompanyBank'
import RegionCompany from '../../components/Companies/RegionCompany'

import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../store/slices/app/comps/snackbar'

import R from '../../../services/app/client-server/request.service'

const EditShowCompany = ({ companyData }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { company_id } = useParams()

  const name = useSelector(state => state.companiesReducer.createData.main.name)
  const ceo = useSelector(state => state.companiesReducer.createData.main.ceo)
  const number = useSelector(state => state.companiesReducer.createData.main.number)
  const email = useSelector(state => state.companiesReducer.createData.main.email)
  const regionId = useSelector(state => state.companiesReducer.createData.main.regionId)
  const address = useSelector(state => state.companiesReducer.createData.main.address)
  const logo = useSelector(state => state.companiesReducer.createData.main.logo)
  const [imageId, setImageId] = useState('')
  const [imageLogoPath, setImageLogoPath] = useState('')

  const statusCompany = useSelector(state => state.companiesReducer.createData.main.status)

  const rs = useSelector(state => state.companiesReducer.createData.over.rs)
  const ks = useSelector(state => state.companiesReducer.createData.over.ks)
  const bank = useSelector(state => state.companiesReducer.createData.over.bank)
  const inn = useSelector(state => state.companiesReducer.createData.over.inn)
  const kpp = useSelector(state => state.companiesReducer.createData.over.kpp)
  const ogrn = useSelector(state => state.companiesReducer.createData.over.ogrn)
  const bik = useSelector(state => state.companiesReducer.createData.over.bik)
  const bankAddress = useSelector(state => state.companiesReducer.createData.over.bankAddress)
  const code = useSelector(state => state.companiesReducer.createData.over.code)
  const num = useSelector(state => state.companiesReducer.createData.over.num)
  const orderNum = useSelector(state => state.companiesReducer.createData.over.orderNum)
  const orderDate = useSelector(state => state.companiesReducer.createData.over.orderDate)

  const [validationErrors, setValidationErrors] = useState({})
  const [isDisabledBtn, setIsDisabledBtn] = useState(false)

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  const formatPhoneNumber = input => {
    const phoneNumber = input.replace(/\D/g, '')
    let formattedPhoneNumber = ''

    if (phoneNumber.length >= 1) {
      formattedPhoneNumber = `+7`
    }
    if (phoneNumber.length > 1) {
      formattedPhoneNumber += ` (${phoneNumber.slice(1, 4)}`
    }
    if (phoneNumber.length > 4) {
      formattedPhoneNumber += `) ${phoneNumber.slice(4, 7)}`
    }
    if (phoneNumber.length > 7) {
      formattedPhoneNumber += `-${phoneNumber.slice(7, 9)}`
    }
    if (phoneNumber.length > 9) {
      formattedPhoneNumber += `-${phoneNumber.slice(9, 11)}`
    }

    return formattedPhoneNumber
  }

  useEffect(() => {
    const data = companyData

    const mainData = [
      { value: 'name', label: data?.name || '' },
      { value: 'ceo', label: data?.director_full_name || '' },
      { value: 'number', label: formatPhoneNumber(data?.phone || '') },
      { value: 'email', label: data?.email || '' },
      { value: 'regionId', label: data?.region?.id || '' },
      { value: 'address', label: data?.address || '' },
      { value: 'status', label: data?.status || '' },
    ]

    const overData = [
      { value: 'rs', label: data.checking_account },
      { value: 'ks', label: data.correspondent_account },
      { value: 'bank', label: data.bank_name },
      { value: 'inn', label: data.inn },
      { value: 'kpp', label: data.kpp },
      { value: 'ogrn', label: data.bin },
      { value: 'bik', label: data.bic },
      { value: 'bankAddress', label: data.bank_address },
      { value: 'code', label: data.sign_cipher },
      { value: 'num', label: data.accreditation_unique_id },
      { value: 'orderDate', label: data.agreement_date },
      { value: 'orderNum', label: data.agreement_number },
    ]

    mainData.forEach(({ value, label }) => {
      dispatch(changeMainData({ value, label }))
    })

    overData.forEach(({ value, label }) => {
      dispatch(changeOverData({ value, label }))
    })

    setImageId(data.logo?.id || '')
    setImageLogoPath(
      data.logo?.path && data.logo.path !== 'data:image/jpeg;base64,' ? data.logo.path : ''
    )
  }, [companyData, dispatch])

  useEffect(() => {
    return () => {
      dispatch(resetCreateData())
    }
  }, [])

  const handleSave = async () => {
    setIsDisabledBtn(true)

    const data = {
      name: name,
      address: address,
      director_full_name: ceo,
      phone: number.replace(/\D/g, ''),
      email: email,
      region_id: regionId,
      inn: inn,
      kpp: kpp,
      bin: ogrn,
      checking_account: rs,
      bank_name: bank,
      bank_address: bankAddress,
      bic: bik,
      correspondent_account: ks,
      accreditation_unique_id: num,
      sign_cipher: code,
      status: statusCompany,
      agreement_number: orderNum,
      agreement_date: orderDate,
      case_issuer_id: 2,
      journalsResponsibles: [
        {
          name: 'first_changed',
          journal_id: '2',
        },
      ],
    }

    if (logo) {
      data.image = logo
    } else {
      data.image_id = imageId
    }

    const { status, data: companiesAddData } = await R.editCompanies(company_id, data)

    if (status === 200) {
      dispatch(setMessage('Компания успешно отредактирована'))
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(resetCreateData())
      dispatch(refreshCompanies())
      dispatch(refreshHeader())
      navigate('/metriva/companies')
    } else if (status === 422) {
      if (companiesAddData.errors) {
        const errors = companiesAddData.errors
        setValidationErrors(errors)
      }
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Компания не отредактирована - ошибка валидации'))
      setIsDisabledBtn(false)
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Ошибка обращения к api'))
      setIsDisabledBtn(false)
    }
  }

  return (
    <React.Fragment>
      <React.Fragment>
        <div className='box'>
          <h2 style={{ marginBottom: '25px' }}>Основные данные компании</h2>

          <NameCompany validationErrors={validationErrors} />

          <div className='flexContainer' style={{ marginBottom: '15px' }}>
            <RegionCompany validationErrors={validationErrors} />

            <AddressCompany validationErrors={validationErrors} />
          </div>

          <LogoCompany
            imageLogoPath={imageLogoPath}
            setImageLogoPath={setImageLogoPath}
            setImageId={setImageId}
          />
        </div>

        <CompanyBank validationErrors={validationErrors} />

        <div style={{ marginTop: '14px' }}>
          <Button
            color='primary'
            onClick={handleSave}
            label='Редактировать компанию'
            disabled={isDisabledBtn}
            fullWidth
          />
        </div>
      </React.Fragment>
    </React.Fragment>
  )
}

export default EditShowCompany
