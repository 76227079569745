// ----------------------------------------------------------------
/* eslint-disable array-callback-return */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Collapse, Typography } from '@mui/material'
import Selector from '../../../../LocalElements/Selector/reactSelect/Selector'

import Input from '../../../../../../ui/input/index.tsx'
import R from '../../../../../../services/app/client-server/request.service'

import useGenerateProtocolData from '../../../../../hooks/useGenerateProtocolDataMi1592'
import AddImage from '../../../water/Gost-8-1012-2022/components/AddImage.jsx'

const MeterData = props => {
  const {
    meterFactoryNumber,
    meterYear,
    meterId,
    modifications,
    selectedAdditionals,
    valueMod,
    valueAdditional,
    valueAnotherMod,
    is_custom_modification,
    changeMeter,
    defaultData,
  } = props
  const dispatch = useDispatch()
  const { defaultValuesStartFinish } = useGenerateProtocolData()

  const pointId = useSelector(state => state.protocolReducer.case.pointId)

  const [data, setData] = useState([])

  const measurementId = useSelector(state => state.protocolReducer.measurementId)

  const [meterInputValue, setMeterInputValue] = useState('')

  // -------------------------------------------------------------
  // Прибор

  const optionsMeter = data
    ? data
        .map(item => ({
          value: item.id,
          label: `${item.fif_number}, ${item.name_si}, ${item.manufacturer.name}`,
        }))
        .filter(item => ~item.label.indexOf(meterInputValue))
    : []

  const getValueMeter = selectedOption => {
    if (selectedOption) {
      dispatch(changeMeter({ value: 'meterId', label: selectedOption.value }))

      const selectedData = data.find(item => item.id === selectedOption.value)
      const selectedModifications = selectedData.modifications

      defaultValuesStartFinish()

      dispatch(
        changeMeter({
          value: 'modifications',
          label: selectedModifications,
        })
      )

      dispatch(
        changeMeter({
          value: 'defaultData',
          label: {
            mpi_cold: selectedData.mpi_cold,
            mpi_hot: selectedData.mpi_hot,
          },
        })
      )

      dispatch(changeMeter({ value: 'selectedMod', label: null }))
      dispatch(changeMeter({ value: 'valueMod', label: null }))
      dispatch(changeMeter({ value: 'diameter', label: '' }))
      dispatch(changeMeter({ value: 'meterType', label: '' }))
      dispatch(changeMeter({ value: 'meterLocation', label: '' }))

      dispatch(changeMeter({ value: 'q_min', label: '' }))
      dispatch(changeMeter({ value: 'q_t', label: '' }))
      dispatch(changeMeter({ value: 'q_max', label: '' }))
      dispatch(changeMeter({ value: 'q_min_limit', label: '' }))
      dispatch(changeMeter({ value: 'q_max_limit', label: '' }))

      dispatch(changeMeter({ value: 'valueAdditional', label: '' }))
      dispatch(
        changeMeter({
          value: 'selectedAdditionals',
          label: [],
        })
      )
      dispatch(changeMeter({ value: 'mpiColdAdditionals', label: null }))
      dispatch(changeMeter({ value: 'mpiHotAdditionals', label: null }))
    }
  }

  // -------------------------------------------------------------
  // Модификация

  const optionsMod = modifications
    ? modifications
        .map(item => ({
          value: item.name,
          label: item.name,
        }))
        .concat([
          {
            value: 'Нет модификации',
            label: 'Нет модификации',
          },
          {
            value: 'another',
            label: 'Другая модификация',
          },
        ])
    : []

  const getValueMod = selectedOption => {
    if (selectedOption) {
      dispatch(
        changeMeter({
          value: 'valueMod',
          label: selectedOption.value,
        })
      )

      const selectedMod = modifications.find(item => item.name === selectedOption.value)

      if (selectedMod) {
        dispatch(
          changeMeter({
            value: 'defaultData',
            label: {
              q_max_limit_cold: selectedMod.q_max_limit_cold ?? '',
              q_max_limit_hot: selectedMod.q_max_limit_hot ?? '',
              q_min_limit_cold: selectedMod.q_min_limit_cold ?? '',
              q_min_limit_hot: selectedMod.q_min_limit_hot ?? '',
              mpi_cold: defaultData.mpi_cold ?? '',
              mpi_hot: defaultData.mpi_hot ?? '',
            },
          })
        )

        const selectedAdditionals =
          selectedMod.additionals && selectedMod.additionals.length > 0
            ? selectedMod.additionals
            : []

        dispatch(
          changeMeter({
            value: 'conversion_factor',
            label: selectedMod.conversion_factor,
          })
        )
        dispatch(
          changeMeter({
            value: 'selectedAdditionals',
            label: selectedAdditionals,
          })
        )
        dispatch(
          changeMeter({
            value: 'selectedMod',
            label: selectedMod,
          })
        )
      } else {
        dispatch(
          changeMeter({
            value: 'defaultData',
            label: {
              q_max_limit_cold: '',
              q_max_limit_hot: '',
              q_min_limit_cold: '',
              q_min_limit_hot: '',
              mpi_cold: defaultData.mpi_cold ?? '',
              mpi_hot: defaultData.mpi_hot ?? '',
            },
          })
        )
        dispatch(changeMeter({ value: 'selectedAdditionals', label: [] }))
        dispatch(changeMeter({ value: 'selectedMod', label: null }))
      }

      dispatch(changeMeter({ value: 'diameter', label: '' }))
      dispatch(changeMeter({ value: 'meterType', label: '' }))
      dispatch(changeMeter({ value: 'meterLocation', label: '' }))
      dispatch(changeMeter({ value: 'q_min', label: '' }))
      dispatch(changeMeter({ value: 'q_t', label: '' }))
      dispatch(changeMeter({ value: 'q_max', label: '' }))
      dispatch(changeMeter({ value: 'q_min_limit', label: '' }))
      dispatch(changeMeter({ value: 'q_max_limit', label: '' }))
      dispatch(changeMeter({ value: 'valueAdditional', label: '' }))
      dispatch(changeMeter({ value: 'mpiColdAdditionals', label: null }))
      dispatch(changeMeter({ value: 'mpiHotAdditionals', label: null }))
    }
  }

  const optionsAdditionals = selectedAdditionals
    ? selectedAdditionals.map(item => ({
        value: item.text,
        label: item.text,
      }))
    : []

  const getValueAdditionals = selectedOption => {
    if (selectedOption) {
      dispatch(
        changeMeter({
          value: 'valueAdditional',
          label: selectedOption.value,
        })
      )

      const selectedAdditional = selectedAdditionals.find(
        item => item.text === selectedOption.value
      )
      if (selectedAdditional) {
        dispatch(
          changeMeter({
            value: 'mpiColdAdditionals',
            label: selectedAdditional.mpi_cold,
          })
        )

        dispatch(
          changeMeter({
            value: 'mpiHotAdditionals',
            label: selectedAdditional.mpi_hot,
          })
        )
      }
    }
  }

  const handleValueModChange = param => {
    dispatch(
      changeMeter({
        value: 'valueAnotherMod',
        label: param,
      })
    )
  }

  useEffect(() => {
    if (valueMod === 'another') {
      dispatch(changeMeter({ value: 'is_custom_modification', label: true }))
    } else {
      dispatch(changeMeter({ value: 'is_custom_modification', label: false }))
    }
  }, [valueMod, dispatch])

  useEffect(() => {
    if (is_custom_modification) {
      dispatch(changeMeter({ value: 'valueMod', label: 'another' }))
    }
  }, [is_custom_modification])

  // ----------------------------------------------------------------
  // Заводской номер

  const handleMeterFactoryNumberChange = param => {
    dispatch(
      changeMeter({
        value: 'meterFactoryNumber',
        label: param,
      })
    )
  }

  // ----------------------------------------------------------------
  // Год

  const yearlist = Array.from({ length: 25 }, (item, index) => (2000 + index).toString())

  yearlist.push('Не выбрано')

  const optionsYear = yearlist
    ? yearlist.map(item => ({
        value: item,
        label: item,
      }))
    : []

  const getValueYear = selectedOption => {
    dispatch(
      changeMeter({
        value: 'meterYear',
        label: selectedOption ? selectedOption.value : null,
      })
    )
  }

  // ----------------------------------------------------------------
  // Список счетчиков

  useEffect(() => {
    ;(async () => {
      const { data: metersList } = await R.getMetersList(
        `?measurement_id=${measurementId}&limit=1000`
      )
      const meterData = metersList.data
      setData(meterData)
    })()
  }, [measurementId])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
          marginBottom: '16px',
        }}
      >
        <Typography variant='h2'>Данные о поверяемом приборе</Typography>

        {(pointId === 4 || pointId === 5) && (
          <AddImage
            title={
              'Согласно пункту № 4.3.1 Методика поверки «Рг» ГОСТ 8.1012-2022 (с изменением №1), при проведении поверки осуществляют цифровую фиксацию (ЦФ) поверки. В рамках ЦФ происходит фотофиксация  воды до начала поверки, на которой необходимо отобразить заводской номер счетчика воды и показания накопленного объема.'
            }
            count={2}
          />
        )}
      </div>
      <div className='flexContainerWithGap'>
        <div className='width50Percent'>
          <Selector
            placeholder={'Выберите прибор'}
            options={optionsMeter}
            onChange={getValueMeter}
            value={meterId ? optionsMeter.find(option => option.value === meterId) : null}
            isClearable={false}
            isRequired={true}
            isSearchable={true}
            noOptionsMessage={() => 'Нет доступных приборов'}
            label={{
              text: 'Поверяемый прибор ( № Госреестра, Завод производитель )',
              topBg: 'white',
              bottomBg: 'white',
            }}
          />
        </div>
        <div className={'width50Percent'}>
          <Selector
            placeholder={'Выберите модификацию'}
            options={optionsMod}
            onChange={getValueMod}
            isClearable={false}
            isSearchable={true}
            isRequired={true}
            noOptionsMessage={() => 'Выберите поверяемый прибор'}
            value={valueMod ? optionsMod.find(option => option.value === valueMod) : null}
            label={{
              text: 'Модификация прибора',
              topBg: 'white',
              bottomBg: 'white',
            }}
          />
        </div>
      </div>
      <Collapse
        in={selectedAdditionals && selectedAdditionals.length > 0}
        timeout={500}
        unmountOnExit
      >
        <div className='width24Point5Percent' style={{ width: '100%' }}>
          <Selector
            placeholder={'Выберите доп. условие'}
            options={optionsAdditionals}
            onChange={getValueAdditionals}
            isClearable={false}
            isSearchable={false}
            isRequired={true}
            value={
              selectedAdditionals
                ? !!optionsAdditionals.find(option => option.value === valueAdditional)
                  ? optionsAdditionals.find(option => option.value === valueAdditional)
                  : null
                : null
            }
            label={{
              text: 'Дополнительное условие',
              topBg: 'white',
              bottomBg: 'white',
            }}
          />
        </div>
      </Collapse>

      <Collapse in={valueMod === 'another'} timeout={500} unmountOnExit>
        <div className='width50Percent'>
          <Input
            type={'text'}
            placeholder={'Введите модификацию прибора'}
            value={valueAnotherMod}
            actions={{
              change: handleValueModChange,
            }}
            label={'Другая модификация прибора'}
          />
        </div>
      </Collapse>

      <div className='flexContainerWithGap'>
        <div className='width50Percent'>
          <Input
            placeholder={'Введите заводской номер'}
            value={meterFactoryNumber}
            type={'text'}
            notRequired={false}
            actions={{
              change: handleMeterFactoryNumberChange,
            }}
            label={'Заводской номер прибора'}
          />
        </div>
        <div className='width50Percent'>
          <Selector
            placeholder={'Выберите год'}
            options={optionsYear}
            onChange={getValueYear}
            value={optionsYear.find(option => option.value === meterYear)}
            isRequired={true}
            isClearable={true}
            isSearchable={true}
            label={{
              text: 'Год выпуска прибора',
              topBg: 'white',
              bottomBg: 'white',
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default MeterData
