import React from 'react'
import { Checkbox, Typography } from '@mui/material'
import { Wrapper, CheckBoxFrame } from '../../styles.ts'

const VerificationMark = props => {
  const { valuePassport, onChangePassport, valueSi, onChangeSi, isTotalAccepted } = props

  return (
    <Wrapper>
      {!isTotalAccepted && (
        <>
          <Typography variant='h2'>Знак поверки</Typography>
          <div style={{ marginLeft: '-12px' }}>
            <CheckBoxFrame>
              <Checkbox checked={valuePassport} onChange={onChangePassport} id='valuePassport' />
              <label
                for='valuePassport'
                style={{ fontSize: '15px', lineHeight: '24px', color: '#4f5960' }}
              >
                Знак поверки в паспорте
              </label>
            </CheckBoxFrame>
            <CheckBoxFrame>
              <Checkbox checked={valueSi} onChange={onChangeSi} id='valueSi' />
              <label
                for='valueSi'
                style={{ fontSize: '15px', lineHeight: '24px', color: '#4f5960' }}
              >
                Знак поверки на системах измерения
              </label>
            </CheckBoxFrame>
          </div>
        </>
      )}
    </Wrapper>
  )
}

export default VerificationMark
