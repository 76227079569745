// ----------------------------------------------------------------
/* eslint-disable array-callback-return */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import EditShowCompany from './EditShowCompanies'
import Tariffs from './Tarrifs'
import BasicTabs from '../../components/LocalElements/TabsMenu/TabsMenu'
import R from '../../../services/app/client-server/request.service'
import {
  setViewVariant,
  setTariff,
  setCompanyId,
  setTariffs,
  setStatus,
} from '../../../store/slices/app/views/companies'

const ShowCompany = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isToggle = useSelector(state => state.toggleReducer.isToggle)
  const { company_id } = useParams()
  const [companyData, setCompanyData] = useState([])
  const viewVariant = useSelector(state => state.companiesReducer.viewVariant)

  const navigateToProtocols = () => {
    navigate('/metriva/companies')
  }

  useEffect(() => {
    ;(async () => {
      const { data: companiesItemData } = await R.getCompaniesItem(company_id)
      const data = companiesItemData.data
      setCompanyData(data)

      dispatch(setTariff(data.subscription_id))
      dispatch(setCompanyId(data.id))

      const initialTariffs = data.tariffs.map(item => ({
        tariffId: item.id,
      }))

      dispatch(setTariffs(initialTariffs))

      dispatch(setStatus(data.status))
    })()
  }, [company_id, dispatch])

  const changeViewVariant = param => dispatch(setViewVariant(param))

  return (
    <React.Fragment>
      <main className={isToggle ? 'main_open' : 'main'}>
        <div className='path_to_back' onClick={navigateToProtocols}>
          <span
            style={{ width: '30px', height: '30px', marginRight: '6px' }}
            className='fas fa-arrow-left icon_back'
          />
          <p>Назад к списку компаний</p>
        </div>
        <React.Fragment>
          <div className='path_to_edit' style={{ marginBottom: '0px' }}>
            <h4>
              {companyData && companyData.name ? companyData.name : 'Название компании не получено'}
            </h4>
          </div>

          <BasicTabs
            callback={changeViewVariant}
            viewVariant={viewVariant}
            tabs={[{ label: 'Общие данные' }, { label: 'Информация о тарифах' }]}
          />

          {viewVariant === 0 && <EditShowCompany companyData={companyData} />}
          {viewVariant === 1 && <Tariffs companyData={companyData} />}
        </React.Fragment>
      </main>
    </React.Fragment>
  )
}

export default ShowCompany
