import React, { useState, useEffect } from 'react'
import AsyncSelect from 'react-select/async'
import makeAnimated from 'react-select/animated'
import { components } from 'react-select'

import CheckCircleIcon from '../../../../../img/icon/CheckCircleIcon'
import VisibilityToggleIcon from '../../../../../img/icon/VisibilityToggleIcon'

const AsyncSelector = ({
  callback,
  value,
  options = async () => {},
  optionKey,
  placeholder = 'Начните вводить искомый адрес',
  label,
  isRequired = false,
}) => {
  const animatedComponents = makeAnimated()

  const selectStyles = {
    control: theme => ({
      ...theme,
      border: 'none',
      outline: 'none',
      boxShadow: '0px 0px 1.5px grey',
      minHeight: '55px',
      // ----------------------------------------------------------------
      // height: '50px',
      // ----------------------------------------------------------------
      fontSize: '14px',
      paddingBottom: '1px',
      paddingTop: '1px',
      borderRadius: '4px',
    }),
    placeholder: theme => ({
      ...theme,
      paddingLeft: '4px',
      paddingBottom: '2px',
    }),
    singleValue: theme => ({
      ...theme,
      paddingLeft: '4px',
    }),
    input: theme => ({
      ...theme,
      paddingLeft: '4px',
    }),
    menu: theme => ({
      ...theme,
      overflow: 'hidden',
      paddingTop: 6,
      paddingBottom: 6,
      width: '90%',
      marginTop: 10,
      border: 'none',
      zIndex: 20,
    }),
    menuList: theme => ({
      ...theme,
      display: 'block',
      position: 'relative',
      width: 'calc(100% + 23px)',
    }),
    option: (theme, { isSelected }) => ({
      ...theme,
      fontSize: '13px',
      padding: '12px 0',
      paddingLeft: '13px',
      paddingBottom: '12.8px',
      lineHeight: '20px',
      cursor: 'pointer',
      color: isSelected ? 'white' : '#2E2E2E',
    }),
    multiValueLabel: theme => ({
      ...theme,
      color: '#123532',
      height: '30px',
      lineHeight: '24px',
      fontSize: '13px',
      paddingLeft: '10px',
      paddingRight: '10px',
    }),
    multiValueRemove: theme => ({
      ...theme,
      marginRight: '8px',
      marginLeft: '2px',
      marginTop: '1px',
      color: '#123532',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transform: state.selectProps.menuIsOpen ? 'rotateX(180deg)' : null,
      transition: 'transform 0.3s ease',
    }),
  }

  const RequiredComponent = props => {
    const { children, selectProps } = props
    return (
      <components.Control {...props}>
        <div style={{ display: 'flex', alignItems: 'center', width: 'calc(100% - 8px)' }}>
          {selectProps.isRequired && (
            <React.Fragment>
              {selectProps.value ? (
                <CheckCircleIcon sx={{ color: '#63E6BE', marginLeft: '14px' }} />
              ) : (
                <VisibilityToggleIcon sx={{ color: '#FFD43B', marginLeft: '14px' }} />
              )}
            </React.Fragment>
          )}
          {children}
        </div>
      </components.Control>
    )
  }

  const [, setValue] = useState('')
  const [selectedValue, setSelectedValue] = useState(null)

  useEffect(() => {
    if (value) {
      setSelectedValue({
        value: value,
        label: value,
      })
    }
  }, [value])

  const handleInputChange = value => {
    setValue(value)
  }

  const handleChange = value => {
    setSelectedValue(value)
    setValue(value)
    callback(value)
  }

  const loadOptions = async inputValue => {
    const { data } = await options(inputValue)

    return data[optionKey]
  }

  return (
    <React.Fragment>
      <div style={{ position: 'relative' }}>
        {label && (
          <div
            style={{
              display: 'flex',
              flexFlow: 'column',
              position: 'absolute',
              zIndex: 2,
              marginTop: '-12px',
              marginLeft: '8px',
            }}
          >
            <span
              style={{
                display: 'block',
                position: 'relative',
                width: '100%',
                height: '12px',
                backgroundColor: label.topBg,
              }}
            />
            <span
              style={{
                display: 'block',
                position: 'relative',
                width: '100%',
                height: '12px',
                backgroundColor: label.bottomBg,
              }}
            />
            <span
              style={{
                display: 'block',
                position: 'relative',
                fontSize: '14px',
                marginTop: '-23px',
                padding: '0px 10px',
                color: '#4f5960',
              }}
            >
              {label.text}
            </span>
          </div>
        )}
        <AsyncSelect
          classNamePrefix='custom-select'
          components={{
            ...animatedComponents,
            Control: RequiredComponent,
          }}
          styles={selectStyles}
          cacheOptions
          defaultOptions
          loadOptions={loadOptions}
          getOptionLabel={e => e.value}
          getOptionValue={e => e.value}
          value={selectedValue}
          onInputChange={handleInputChange}
          onChange={handleChange}
          placeholder={placeholder}
          noOptionsMessage={() => 'Ничего не найдено'}
          loadingMessage={() => 'Подождите, идет загрузка'}
          isRequired={isRequired}
        />
      </div>
    </React.Fragment>
  )
}

export default AsyncSelector
